import React, { Component } from 'react'
import { graphql } from 'gatsby'
import scrollToComponent from 'react-scroll-to-component'

// library to make the navigation bar sticky
import Sticky from 'react-sticky-el'

import InViewMonitor from 'react-inview-monitor'
import Scrollspy from 'react-scrollspy'

import './style.scss'
import '../History/style.scss'
import PrevNextNav from '../PrevNextNav'
import InnovationConnector from './InnovationConnector'

export default class Innovation extends Component {
  constructor(props) {
    super(props)
    this.scrollTo = this.scrollTo.bind(this)
    this.nextItem = this.nextItem.bind(this)
    this.prevItem = this.prevItem.bind(this)
    this.getActiveIndex = this.getActiveIndex.bind(this)
    this.maxIndex = props.data.length - 1
    this.sectionIds = this.props.data.map(({ node }) => node.id)
  }

  state = {
    activeItem: this.props.data[0].node.id,
  }

  scrollTo(item) {
    scrollToComponent(item, {
      offset: -80,
      align: 'top',
      duration: 500,
    })
  }

  getActiveIndex() {
    return this.sectionIds.indexOf(this.state.activeItem)
  }

  nextItem() {
    // check the index of the current active item
    let nextIndex = this.getActiveIndex() + 1
    if (nextIndex >= this.maxIndex) nextIndex = this.maxIndex

    this.scrollTo(this[this.sectionIds[nextIndex]])
  }

  prevItem() {
    let prevIndex = this.getActiveIndex() - 1
    if (prevIndex < 0) prevIndex = 0
    this.scrollTo(this[this.sectionIds[prevIndex]])
  }

  render() {
    const { data } = this.props
    let prevDot = ''

    return (
      <div className=" position-relative">
        <div className="container position-relative c-history-container">
          <Sticky
            className="c-sticky-bit d-none d-lg-block"
            boundaryElement=".c-history-container"
            style={{ position: 'relative', zIndex: 100 }}
            hideOnBoundaryHit={false}
            bottomOffset={80}
            hideOnBoundaryHit={false}
          >
            <div className="c-history-nav d-none d-sm-flex">
              <Scrollspy
                className="c-history-nav__inner"
                componentTag="div"
                offset={-100}
                items={data.map(item => item.node.id)}
                currentClassName="is-active"
                onUpdate={target => {
                  if (target) {
                    this.setState({ activeItem: target.id })
                  }
                }}
              >
                {data.map(item => {
                  return (
                    <a
                      href={'#' + item.node.id}
                      key={item.node.id}
                      onClick={e => {
                        e.preventDefault()
                        this.scrollTo(this[item.node.id])
                      }}
                      className={`c-history-nav__button`}
                    >
                      {item.node.frontmatter.title}
                    </a>
                  )
                })}
              </Scrollspy>
              <PrevNextNav
                vertical
                nextHandler={this.nextItem}
                prevHandler={this.prevItem}
              />
            </div>
          </Sticky>
          {data.map((item, parentIndex) => {
            const {
              node: {
                id,
                html,
                frontmatter: { title, subItems, color, innovationIcon },
              },
            } = item

            return (
              <section
                id={item.node.id}
                className={`${parentIndex != data.length - 1 &&
                  'pb-5'} c-innovation-wrapper`}
                ref={section => (this[item.node.id] = section)}
                key={item.node.id}
              >
                <InViewMonitor
                  classNameInView="is-active"
                  toggleClassNameOnInView={true}
                >
                  <div className="row c-innovation-intro">
                    <div className="col-sm-6">
                      {innovationIcon && (
                        <img
                          className="c-innovation-img"
                          src={innovationIcon.childImageSharp.original.src}
                          alt={title}
                        />
                      )}
                      <h2 style={{ color: color }}>
                        {item.node.frontmatter.title}
                      </h2>
                      <div dangerouslySetInnerHTML={{ __html: html }} />
                    </div>
                  </div>
                </InViewMonitor>
                {subItems.map((subItem, index) => {
                  const {
                    node: {
                      id,
                      html,
                      frontmatter: { title },
                    },
                  } = subItem
                  const newDot = `dotconnectorP${parentIndex}C${index}`
                  const oldDot = prevDot
                  prevDot = newDot
                  return (
                    <InViewMonitor
                      classNameInView="is-active"
                      toggleClassNameOnInView={true}
                      key={id}
                    >
                      <div id={id}>
                        <article
                          className="row position-relative my-5"
                          style={{ zIndex: 2 }}
                        >
                          <div
                            className={`c-innovation-dot  ${newDot}`}
                            style={{ color: color }}
                          />

                          <div
                            className={`c-innovation-sub-item col-sm-7 p-4  ${index %
                              2 ===
                              1 && 'offset-sm-2'}`}
                          >
                            <h3>
                              <span
                                className="c-innovation-number"
                                style={{ color: color }}
                              >
                                0{index + 1}
                              </span>{' '}
                              {title}
                            </h3>
                            <div dangerouslySetInnerHTML={{ __html: html }} />
                          </div>
                        </article>
                        <InnovationConnector
                          borderWidth={5}
                          borderColor={color}
                          className="c-innovation-line"
                          from={oldDot}
                          to={newDot}
                          zIndex={0}
                        />
                      </div>
                    </InViewMonitor>
                  )
                })}
              </section>
            )
          })}
        </div>
      </div>
    )
  }
}

export const InnovationQuery = graphql`
  fragment InnovationSub on MarkdownRemark {
    id
    html
    frontmatter {
      title
    }
  }

  fragment Innovation on MarkdownRemark {
    frontmatter {
      innovationItems: innovation_items {
        node: childMarkdownRemark {
          id
          html
          frontmatter {
            title
            color
            innovationIcon {
              id
              childImageSharp {
                original {
                  src
                }
              }
            }
            subItems {
              node: childMarkdownRemark {
                ...InnovationSub
              }
            }
          }
        }
      }
    }
  }
`
