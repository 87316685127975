import React from 'react'
import { graphql } from 'gatsby'

import HeroSingle from '../components/HeroSingle'
import ContactCTA from '../components/ContactCTA'
import CTABlock from '../components/CTABlock'
import Innovation from '../components/Innovation'
import Layout from '../components/Layout'
import SEO from '../components/SEO/index'
import CTABanner from '../components/CTABanner'

class Index extends React.Component {
  // dispatch a resize event after 300ms,
  // fixes the line connector on rare occastions where it doesn't render correctly at first
  componentDidMount() {
    setTimeout(() => {
      const resizeEvent = new Event('resize')
      window.dispatchEvent(resizeEvent)
    }, 300)
  }

  render() {
    const { data, location } = this.props
    return (
      <Layout location={location}>
        <SEO path={location.pathname} frontmatter={data.page.frontmatter} />
        <div className="bg-light">
          <div style={{ zIndex: 20 }} className="position-relative">
            <HeroSingle data={data.page} />
            <CTABlock compact data={data.page.frontmatter.cta_block} />
          </div>
          <Innovation data={data.page.frontmatter.innovationItems} />
          <ContactCTA />
        </div>
        <div className="bg-light c-footer">
          <CTABanner data={data.page.frontmatter.cta_banner} />
        </div>
      </Layout>
    )
  }
}

export default Index

export const innovationQuery = graphql`
  query innovationQuery {
    page: markdownRemark(fields: { slug: { eq: "/innovation/" } }) {
      id
      ...SEO
      ...ctaBanner
      ...ctaBlock
      ...HeroSingle
      ...Innovation
    }
  }
`
